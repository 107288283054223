.panel {
  /* Enter transition */
  transition-property: opacity, height, display;
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
  /* Allow height:auto transition */
  interpolate-size: allow-keywords;
  /* Allow exit animation */
  transition-behavior: allow-discrete;
}

@starting-style {
  /* Initial styles (before enter transition) */
  .panel {
    opacity: 0.5;
  }
}
.panel[hidden] {
  opacity: 0;
  /* Exit transition */
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

@media (prefers-reduced-motion: no-preference) {
  @starting-style {
    .panel {
      opacity: 0;
      height: 0;
    }
  }
  .panel[hidden] {
    height: 0;
  }
}
